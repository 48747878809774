// 1. Your custom variables and variable overwrites.
$global-color: #1f1f1f;
$global-link-color: #ff2164;
$base-link-hover-text-decoration: none;
$global-primary-background: #ec21ff;
$global-secondary-background: #004ddd;
$global-success-background: #0eb42a;
$global-danger-background: #ff0e0e;

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-button() { 
    font-weight: 600;
    border-radius: 8px;
}

// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";

// Your custom css
::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}
::-webkit-scrollbar-track {
    background: #111111;
}
::-webkit-scrollbar-thumb {
    background: #111111;
    border-radius: 3px;
}